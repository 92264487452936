var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { checkDevice, copyToClipboard, checkIosVersion, ttfb, } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import assets from "Assets/js/modules/jsAssets";
import siteHeaderJs from "Assets/js/components/site-header";
import forms from "Assets/js/components/forms";
import cdbForm from "Assets/js/modules/cdb-form";
import siteModal from "Assets/js/modules/modal";
import shop from "Assets/js/modules/shop";
lazyloader.options.rootMargin = "100% 0px 500px 0px";
lazyloader.options.assetArray = assets.dynamicAssets;
lazyloader.options.debugLogMessages = false;
lazyloader.options.filePath = "js/blocks/";
let shopJs = () => {
    const loadEShop = document.querySelector("html").dataset.eshop;
    if (loadEShop === "true") {
        shop.shopShopify(true);
        shop.checkCart(); // eager-loading Shopify?
    }
};
let runLazyLoad = () => {
    lazyloader.lazyloaderInit();
};
function headerJs() {
    const header = document.querySelector(".site-header");
    siteHeaderJs({ block: header });
}
function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
window.addEventListener("DOMContentLoaded", () => {
    function runSiteJs() {
        return __awaiter(this, void 0, void 0, function* () {
            // Create an array of functions to run:
            const tasks = [
                ttfb,
                checkDevice,
                checkIosVersion,
                runLazyLoad,
                shopJs,
                forms,
                cdbForm,
                siteModal,
                copyToClipboard,
                headerJs,
            ];
            // Loop over the tasks:
            while (tasks.length > 0) {
                // Shift the first task off the tasks array:
                const task = tasks.shift();
                // Run the task:
                task();
                // Yield to the main thread:
                yield yieldToMain();
            }
        });
    }
    runSiteJs();
});
