import {detectVerticalDirection} from 'Assets/js/modules/helpers';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		
		const desktopBreakpoint = 1024;
		
		const documentBody = document.querySelector('body');
		const hamburger = document.querySelector('.site-hamburger');
		const navContainer = document.querySelector('.site-nav');
		const primaryNav = document.querySelector('.site-nav__primary');
		const header = document.querySelector('.site-header');
		const subnav = document.querySelectorAll('.site-nav > ul > li');
		let dropdown = document.querySelector('.site-nav__switch__dropdown');
		const ctbButton = document.querySelector('.site-nav__secondary__shop');
		const betterReviewCountSpan = document.querySelector(
			'.better-reviews-personal-likes-count'
		);
		const heartOutline = document.querySelector(
			'.site-nav__better-reviews-personal-likes-image'
		);
		const heartFilled = document.querySelector(
			'.site-nav__better-reviews-personal-likes-image-filled'
		);
		function updateAriaLikesCounter() {
			const likesCount = parseInt(betterReviewCountSpan.textContent) || 0;
			betterReviewCountSpan.textContent = likesCount;
			betterReviewCountSpan.setAttribute('aria-label', `${likesCount} favourited products and cocktails`);
			betterReviewCountSpan.setAttribute('data-better-reviews-personal-likes-total', likesCount);
		}
		document.addEventListener('DOMContentLoaded', function() {
			const skipLink = document.querySelector('.skip-link');
			const mainContent = document.getElementById('main-content');		  
			skipLink.focus();		  
			skipLink.addEventListener('click', function(e) {
			  e.preventDefault();
			  mainContent.focus();
			  mainContent.scrollIntoView({behavior: 'smooth', block: 'start'});
			});
		});
		function updateNavFavesDisplay() {
			const currentLikesCount = parseInt(betterReviewCountSpan.textContent) || 0;
			if (currentLikesCount === 0) {
				heartOutline.classList.remove('outline-hide');
				heartFilled.classList.add('filled-hide');
			} else {
				heartOutline.classList.add('outline-hide');
				heartFilled.classList.remove('filled-hide');
			}
		}

		updateAriaLikesCounter(); 
		updateNavFavesDisplay(); 

		const config = {attributes: true, childList: true, subtree: true};
		const callback = function (mutationList) {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList') {
					updateAriaLikesCounter();
					updateNavFavesDisplay();
				}
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(betterReviewCountSpan, config);

		const alwaysVisibleSubnav = document.querySelector(
			'.site-nav__primary li.has-children a.current-menu-item + .site-nav__sub'
		);

		const getCookieValue = (name) =>
			document.cookie
				.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
				?.pop() || '';

		const setupNavigationClasses = () => {
			if (alwaysVisibleSubnav) {
				header.classList.add('subnav-active');
				checkCurrentAnchorLinks(alwaysVisibleSubnav);
			}

			// highlight clicked category for Stories or the default one.
			if (
				getCookieValue('storiesCategory') != '' ||
				document
					.querySelector('body')
					.classList.contains('single-stories')
			) {
				const highlightedCategory = document.querySelector(
					".stories-navigation + .site-nav__sub a[data-id='" +
						getCookieValue('storiesCategory') +
						"']"
				);
				if (highlightedCategory != null) {
					highlightedCategory.classList.add('current-menu-item');
					document.querySelector(
						'.site-nav__switch__dropdown__selected'
					).textContent = highlightedCategory.textContent;
				} else {
					document
						.querySelector(
							'.stories-navigation + .site-nav__sub li.closeSub + li a'
						)
						.classList.add('current-menu-item');
				}
			}
		};

		const checkCurrentAnchorLinks = (nav) => {
			let anchors = [];
			nav.querySelectorAll('li a').forEach((link) => {
				if (link.href.indexOf('#') != -1) {
					let anchor = link.href.split('#')[1];
					anchors.push(anchor);
					link.addEventListener('click', (e) => {
						e.preventDefault;
						// Mobile version.
						closeSubNavs();

						// Fix for the carousel.
						const carouselContainer = document.querySelector(
							'.carousel-multi-layout__temp-container'
						);
						if (carouselContainer) {
							carouselContainer.remove();
							document
								.querySelector(
									'.site-header.subnav-active.scrolled, .site-header.subnav-active.hide'
								)
								.classList.remove('hide');
							document
								.querySelector('html')
								.classList.remove('lock-position');
						}
						//document.location.hash = anchor;
						smoothScroll(
							document.querySelector('#' + anchor),
							1000
						);
						link.classList.add('current-menu-item');
						dropdown.querySelector(
							'.site-nav__switch__dropdown__selected'
						).textContent = link.textContent;
						resetSubnavActiveClasses(nav, link);
					});
				}
			});

			// if there are anchor links on the page, detect current one on scroll
			if (anchors.length > 0) {
				let firstBlockViewer = false;
				resetSubnavActiveClasses(nav, nav.querySelector('a'));
				document.addEventListener('scroll', (e) => {
					anchors.forEach((anc, index) => {
						if (isInViewport(document.getElementById(anc))) {
							resetSubnavActiveClasses(
								nav,
								nav.querySelector("a[href*='#" + anc + "']")
							);
							if (index == 0) {
								firstBlockViewer = true;
							} else {
								firstBlockViewer = false;
							}
						}
					});
					if (
						firstBlockViewer &&
						isInViewport(
							document.querySelector(
								'.main-container section:first-child'
							)
						)
					) {
						resetSubnavActiveClasses(
							nav,
							nav.querySelector('a.hide-desktop')
						);

						firstBlockViewer = false;
					}
				});
			}
		};

		const smoothScroll = (target, duration) => {
			let targetPosition = target.getBoundingClientRect().top;
			let startPosition = window.pageYOffset;
			let startTime = null;

			const ease = function (t, b, c, d) {
				t /= d / 2;
				if (t < 1) return (c / 2) * t * t + b;
				t--;
				return (-c / 2) * (t * (t - 2) - 1) + b;
			};

			const animation = function (currentTime) {
				if (startTime === null) startTime = currentTime;
				const timeElapsed = currentTime - startTime;
				const run = ease(
					timeElapsed,
					startPosition,
					targetPosition,
					duration
				);
				window.scrollTo(0, run);
				if (timeElapsed < duration) requestAnimationFrame(animation);
			};
			requestAnimationFrame(animation);
		};

		const resetSubnavActiveClasses = (nav, newActiveElement) => {
			nav.querySelectorAll('a.current-menu-item').forEach((current) =>
				current.classList.remove('current-menu-item')
			);
			if (newActiveElement != null) {
				newActiveElement.classList.add('current-menu-item');
				dropdown.querySelector(
					'.site-nav__switch__dropdown__selected'
				).textContent = newActiveElement.textContent;
			}
		};

		const closeSubNavs = () => {
			if (dropdown) {
				dropdown.classList.add('closed');
			}
			documentBody.classList.remove('hovernav-active');
			document
				.querySelectorAll('.site-nav__sub.active')
				.forEach((item) => {
					item.classList.remove('active');
				});
			navContainer.classList.remove('dropdown-active');
			navContainer.classList.remove('active');
			documentBody.classList.remove('hovernav-active');
			document.documentElement.classList.remove('modal-active');
			primaryNav.style.removeProperty('margin-top');
			navContainer.style.removeProperty('height');
			document.querySelectorAll('.site-nav__sub').forEach((item) => {
				item.style.removeProperty('height');
			});
		};

		const isInViewport = (element) => {
			const position = element.getBoundingClientRect();
			return position.top >= 0 && position.top < window.innerHeight / 2;
		};

		const openHoverNav = (element) => {
			if (dropdown) {
				dropdown.classList.remove('closed');
			}
			document
				.querySelectorAll('.site-nav__sub.active')
				.forEach((item) => {
					item.classList.remove('active');
				});
			element.querySelector('.site-nav__sub').classList.add('active');
			documentBody.classList.add('hovernav-active');

			if (window.innerWidth >= desktopBreakpoint) {
				element.addEventListener(
					'mouseleave',
					function (ev) {
						var goingto = ev.relatedTarget || ev.toElement;
						if (goingto) {
							if (
								!goingto.classList.contains(
									'site-nav__secondary__inner'
								) &&
								!goingto.classList.contains(
									'site-nav__secondary'
								) &&
								!goingto.classList.contains(
									'site-nav__secondary__inner__left'
								)
							) {
								closeSubNavs();
							}
						}
					},
					false
				);
			} else {
				//navContainer.classList.add('active');
				header.classList.add('secondary-active-in-sub');
				document.documentElement.classList.add('modal-active');
				navContainer.style.height = window.innerHeight + 'px';

				primaryNav.style.marginTop = header.offsetHeight + 'px';
				navContainer
					.querySelectorAll('.site-nav__sub')
					.forEach((sub) => {
						sub.style.height = primaryNav.offsetHeight + 'px';
					});
			}
		};

		// Delegated event to make sure moved filters works.
		header.addEventListener('click', (event) => {
			if (event.target.closest('.site-hamburger')) {
				navContainer.classList.toggle('active');
				documentBody.classList.remove('hovernav-active');
				document.documentElement.classList.toggle('modal-active');
				navContainer.style.height = window.innerHeight + 'px';
				primaryNav.style.marginTop = header.offsetHeight + 'px';
				navContainer
					.querySelectorAll('.site-nav__sub')
					.forEach((sub) => {
						sub.style.height = primaryNav.offsetHeight + 'px';
					});
			} else if (
				event.target.classList.contains(
					'site-nav__switch__dropdown__selected'
				)
			) {
				console.log('clicked dropdown');
				dropdown = event.target.parentNode;
				if (dropdown.classList.contains('closed')) {
					navContainer.classList.add('active');
					navContainer.classList.add('dropdown-active');
					openHoverNav(
						navContainer.querySelector(
							'.meganav.current-menu-parent'
						)
					);
				} else {
					closeSubNavs();
				}
			} else if (
				event.target.classList.contains('primary-link') &&
				event.target.classList.contains('menu-item-has-children')
			) {
				if (window.innerWidth < desktopBreakpoint) {
					event.preventDefault();
					openHoverNav(event.target.parentElement);
				}
			} else if (
				event.target.closest('li') &&
				event.target.closest('li').classList.contains('closeSub')
			) {
				documentBody.classList.remove('hovernav-active');
			} else if (event.target.classList.contains('filter-link')) {
				dropdown.querySelector(
					'.site-nav__switch__dropdown__selected'
				).textContent = event.target.textContent;
				closeSubNavs();
			}
		});

		const setupInitialListenners = () => {
			subnav.forEach((li) => {
				if (window.innerWidth >= 1024) {
					li.addEventListener(
						'mouseenter',
						function (event) {
							if (
								event.target.querySelector('a + .site-nav__sub')
							) {
								if (
									!event.target
										.querySelector('a + .site-nav__sub')
										.checkVisibility() ||
									event.target.classList.contains('meganav')
								) {
									openHoverNav(event.target);
								}
							}
						},
						false
					);
				}
			});
			hamburger.addEventListener('click', function () {
				const expanded = hamburger.getAttribute('aria-expanded') === 'true';
  				hamburger.setAttribute('aria-expanded', !expanded);
  				hamburger.setAttribute('aria-label', expanded ? 'Open site menu' : 'Close site menu');
			});
			window.addEventListener(
				'resize',
				function () {
					closeSubNavs();
				},
				true
			);
		};

		const checkScroll = () => {
			const top =
				window.pageYOffset || document.documentElement.scrollTop;
			if (top > 100) {
				header.classList.add('scrolled');
			} else {
				header.classList.remove('scrolled');
			}
		};

		const showCtbButton = () => {
			if (ctbButton) {
				const {head} = document;
				const sdk = document.createElement('script');
				let ctb = null;
				sdk.async = true;
				sdk.src =
					'https://assets-ctb.pernod-ricard.io/latest/js/ctbuy-sdk.js';
				head.appendChild(sdk);

				window.ctbuyReady = (ready) => {
					if (!ctb) {
						// eslint-disable-next-line no-undef
						ctb = ready(ctbuyConfig);
					}
				};
			}
		};

		// Detect the scroll and direction and run the function. False - to leave the listeners, as default function option is to detect first move and remove the listeners.
		//detectVerticalDirection((dir) => navScrollBehaviour(dir), false);

		setupNavigationClasses();
		setupInitialListenners();
		showCtbButton();

		//This closes the navigation when the page has been loaded from the (back forward) bfcache.
		window.addEventListener('pageshow', (event) => {
			if (event.persisted) {
				closeSubNavs();
			} else {
				//silence is golden
			}
		});
		window.addEventListener('scroll', checkScroll);
	} catch (error) {
		console.error(error);
	}
}
