export default function modalJs() {
	try {
		// Close modal when a close button in the modal has been pressed
		let modals = Array.from(document.querySelectorAll('.modal-chivas'));
		modals = modals.filter(modals => modals != document.getElementById('competition_terms_and_conditions'));

		const modalTriggers = document.querySelectorAll('.modal__trigger');
		const modalCloseButtons = document.querySelectorAll('.modal__close');
		const htmlElement = document.documentElement;
		const sortedModals = Array.from(modals).sort(
			(a, b) => a.dataset.order - b.dataset.order
		);

		// Move all modals to bottom of body so that position
		// of parent doesn't become an issue
		sortedModals.forEach((modal) => {
			document.body.appendChild(modal);
		});

		modalTriggers.forEach((trigger) => {
			trigger.addEventListener('click', (event) => {
				event.preventDefault();
				const { modalName } = trigger.dataset;
				const thisModal = document.querySelector(
					`.modal-chivas[data-modal-name="${modalName}"]`
				);
				const container = thisModal.querySelector('.modal__container');
				const { direction } = thisModal.dataset;
				container.classList.remove('fade-in-left');
				container.classList.remove('fade-in-right');
				container.classList.remove('fade-out-left');
				container.classList.remove('fade-out-right');
				thisModal.classList.add('modal--visible');

				if (direction === 'left') {
					container.classList.add('fade-in-left');
				} else if (direction === 'right') {
					container.classList.add('fade-in-right');
				}
				htmlElement.classList.add('modal-active');
			});
		});

		modalCloseButtons.forEach((closeButton) => {
			closeButton.addEventListener('click', (event) => {
				event.preventDefault();
				// remove instead of toggle because it might activate inactive modals on the page
				const activeModal = document.querySelector('.modal--visible');
				const container =
					activeModal.querySelector('.modal__container');
				const { direction } = activeModal.dataset;
				if (direction === 'left') {
					container.classList.add('fade-out-left');
				} else if (direction === 'right') {
					container.classList.add('fade-out-right');
				}
				document
					.getElementsByTagName('html')[0]
					.classList.remove('disable-scroll');
				htmlElement.classList.remove('modal-active');
				setTimeout(() => {
					activeModal.classList.remove('modal--visible');
					container.classList.remove('fade-in-left');
					container.classList.remove('fade-in-right');
					container.classList.remove('fade-out-left');
					container.classList.remove('fade-out-right');
				}, 800);
			});
		});
	} catch (error) {
		console.error(error);
	}
}
