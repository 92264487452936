import {isEmailValid} from '@pernod-ricard-global-cms/jsutils';
import modalJs from './modal';

const regexDayMonthYear =
	/^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/; // eslint-disable-line

const currentMarket = document.documentElement.lang;
const countrySelectors = document.querySelectorAll('#countryCode5');

let currentMarketAfterDash = getSubstringAfterDash(currentMarket);

// Is dob valid.
function isDateValid(date) {
	return regexDayMonthYear.test(date);
}

function getSubstringAfterDash(str) {
	let parts = str.split('-');
	return parts.length > 1 ? parts.slice(1).join('-') : "";
}

countrySelectors.forEach((countrySelector)=>{
	for (let i = 0; i < countrySelector.options.length; i++) {
		if ( countrySelector.options[i].value.toLowerCase() === currentMarketAfterDash) {
			if (countrySelector.options[countrySelector.selectedIndex]) {
				countrySelector.options[countrySelector.selectedIndex].removeAttribute('selected');
			}
			countrySelector.options[i].setAttribute('selected', 'selected');
			break;
		}
	}
})

// Show error function.
function showError(el) {
	const spanTag = document.createElement('div');
	spanTag.setAttribute('class', 'cdb-not-valid-tip');

	el.classList.add('input-error');

	if (el.type === 'checkbox' || el.tagName === 'SELECT') {
		el.parentElement.before(spanTag);
	} else {
		el.before(spanTag);
	}

	switch (el.name) {
		case 'firstname':
			spanTag.innerHTML = formErrorMessages.firstNameError;
			break;
		case 'email':
			spanTag.innerHTML = formErrorMessages.emailError;
			break;
		case 'birthday':
			spanTag.innerHTML = formErrorMessages.dobError;
			break;
		case 'countryCode':
			spanTag.innerHTML = formErrorMessages.countryError;
			break;
		default:
			spanTag.innerHTML = formErrorMessages.checkboxError;
	}
	return false;
}

// Clear error function.
function clearError(el) {
	el.classList.remove('input-error');
	const notValidTipElement =
		el.type == 'checkbox' || el.tagName === 'SELECT'
			? el.parentElement.previousElementSibling
			: el.previousElementSibling;
	if (notValidTipElement !== null) {
		notValidTipElement.remove();
	}
	return false;
}

// Set expiry date.
function setExpiryDate(key, ttl) {
	const date = new Date();
	date.setDate(date.getDate() + ttl);
	localStorage.setItem(key, JSON.stringify(date.getTime()));
}

function signUpBarExpiryDate(mutationList, observer) {
	mutationList.forEach(function (mutation) {
		mutation.addedNodes.forEach(function (nodes) {
			if (nodes.className === 'confirmation_message') {
				// Set Sign Up Block Expiry Date to 30 days.
				setExpiryDate('displaySignUpBlock', 30);
				observer.disconnect();
				modalJs();
			}
		});
	});

	return false;
}

export default function cdbForm() {
	try {
		const cdbForms = document.querySelectorAll('.cdb_form_container');

		if (cdbForms.length > 0) {
			cdbForms.forEach((form) => {
				const submitButton = form.querySelector('.cdb-submit');
				const inputs = form.querySelectorAll(
					'.cdb_form_field [required]'
				);

				const submitForm = (e) => {
					let formIsValid = true;

					inputs.forEach((form) => {
						clearError(form);
					});

					inputs.forEach((input) => {
						if (
							input.type == 'email' &&
							!isEmailValid(input.value)
						) {
							formIsValid = false;
							showError(input);
						} else if (
							input.name == 'birthday' &&
							!isDateValid(input.value)
						) {
							showError(input);
						} else {
							if (!input.validity.valid) {
								formIsValid = false;
								showError(input);
							}
						}
					});

					if (!formIsValid) {
						e.stopPropagation();
						e.preventDefault();
					}
				};

				if (inputs.length > 0) {
					inputs.forEach((input) => {
						input.addEventListener('focus', (e) => {
							clearError(e.target);
						});
					});
				}

				submitButton.addEventListener('click', (e) => {
					let observer = new MutationObserver(signUpBarExpiryDate);

					submitForm(e);
					observer.observe(form, {
						childList: true,
						subtree: true
					});
				});
			});
		}
	} catch (error) {
		console.error(error);
	}
}
