export default function forms() {
	let selectLists = document.querySelectorAll('.form-dropdown');

	selectLists.forEach((selectList) => {
		let htmlElement = selectList.querySelector('select');

		let selectedContainer = selectList.querySelector(
			'.form-dropdown__selected'
		);
		console.log(htmlElement);
		console.log(selectedContainer);

		// Create new dropdown with items.
		let dropdown = document.createElement('ul');
		dropdown.classList.add('form-dropdown__items', 'hide');

		Array.from(htmlElement.options).forEach((optionElement, index) => {
			// Copy all options to the list.
			let item = document.createElement('li');
			item.innerHTML = optionElement.innerHTML;
			item.setAttribute('data-index', index);
			item.addEventListener('click', function (e) {
				// on click show selected item at the top.
				htmlElement.selectedIndex = this.getAttribute('data-index');
				selectedContainer.innerHTML = this.innerHTML;
				selectedContainer.classList.remove('placeholder');
			});
			dropdown.appendChild(item);
		});
		selectList.appendChild(dropdown);

		// Open dropdown on click.
		selectedContainer.addEventListener('click', function (e) {
			e.stopPropagation();
			dropdown.classList.toggle('hide');
			this.classList.toggle('select-arrow-active');
		});
	});

	const closeAllSelect = () => {
		document
			.querySelectorAll('.form-dropdown .select-arrow-active')
			.forEach((item) => {
				item.classList.remove('select-arrow-active');
			});

		document
			.querySelectorAll('.form-dropdown .form-dropdown__items')
			.forEach((item) => {
				item.classList.add('hide');
			});
	};

	document.addEventListener('click', closeAllSelect);
}
